import { Injectable } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { CustomerContactRequestItem, CustomerContactResponseItem } from '@domain/app/contact.domain';
import { RoutingPathMain } from '@enums';
import { ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel-service/shared-panel.service';
import { TopicService } from '@services/topic-service/topic-service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

export interface ContactData {
  id: string;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContentElementService {
  public appointmentDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  public contactAdded: BehaviorSubject<ContactData> = new BehaviorSubject<ContactData>(null);
  public contactList: ContactData[] = [];
  public customerContacts: CustomerContactResponseItem[] = [];

  constructor(
    private snackBarService: SnackBarTemplatesService,
    private topicService: TopicService,
    private queryService: QueryService,
    private clientService: ClientService,
    private panelService: SharedPanelService,
    private footerService: SharedFooterService,
    private actionService: ActionService,
    private contextService: ContextService
  ) {}

  public async addSubtopicToAgenda(subtopicToAdd: string): Promise<void> {
    try {
      let selectedSubtopicIds = this.topicService.consultationSelectedSubtopicIds(this.topicService.subtopicData);
      let currentSubtopic = this.panelService.currentSubtopicId;

      //add subtopicToAdd behind currentSubtopic in list, if possible
      if (currentSubtopic !== '-1') {
        const currentSubtopicIndex = selectedSubtopicIds.indexOf(currentSubtopic);
        selectedSubtopicIds.splice(currentSubtopicIndex + 1, 0, subtopicToAdd);
      } else {
        selectedSubtopicIds.splice(0, 0, subtopicToAdd);
      }

      //send updated subtopic list to backend
      const sendObj = { selectedSubtopicIds: selectedSubtopicIds };
      await lastValueFrom(
        this.queryService.putSubtopicDataByConsultationId(this.clientService.consultationId, sendObj)
      );

      //update agenda panel
      if (!this.panelService.panelOpen.value) {
        this.footerService.handleFooterAction({ owner: 'bgzv', id: 'open-agenda' });
      }
      if (currentSubtopic) {
        if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
          await this.actionService.setAction({ target: 'consultation', action: 'refresh-question-group' });
        }
        await this.panelService.updatePanelConfig(this.clientService.consultationId, currentSubtopic);
      } else {
        await this.panelService.updatePanelConfig(this.clientService.consultationId);
      }

      this.snackBarService.openSnackBar({ type: SnackbarType.SUCCESS, message: 'Unterthema zur Agenda hinzugefügt.' });
    } catch (error) {
      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message: 'Beim Hinzufügen des Unterthemas ist ein Fehler aufgetreten. Bitte überprüfen Sie die Konfiguration.',
      });
    }
  }

  /***** CONTACT FUNCTIONS *****/

  public async fetchContactData(): Promise<void> {
    this.customerContacts = [];
    let result = await lastValueFrom(this.queryService.getCustomerContacts(this.clientService.consultationId));
    result = result.filter(x => x.customerContactType === 'CONSULTANT' || x.customerContactType === 'CUSTOMER');
    result.forEach(contact => {
      const alreadyInList = this.customerContacts.find(
        c => c.email === contact.email && c.firstName === contact.firstName && c.lastName === contact.lastName
      );
      if (!alreadyInList) {
        this.customerContacts.push(contact);
      }
    });
    await this.updateContactList();
  }

  public addCustomerContact(customerContact: CustomerContactRequestItem): void {
    const { firstName, lastName, email } = customerContact;
    const contact = this.customerContacts.find(
      c => c.email === email && c.firstName === firstName && c.lastName === lastName
    );
    if (!contact) {
      this.customerContacts.push(customerContact);
      this.updateContactList();
      this.contactAdded.next({
        id: customerContact.id,
        label: `${customerContact.formOfAddress} ${customerContact.firstName} ${customerContact.lastName}`,
      } as ContactData);
    }
  }

  private async updateContactList(): Promise<void> {
    this.contactList = this.customerContacts.map(customerContact => {
      const { formOfAddress, firstName, lastName, id } = customerContact;
      const label = [formOfAddress, firstName, lastName].filter(Boolean).join(' ');
      return { id, label };
    });
  }
}
