<aside
  class="d-flex flex-column app-height align-items-center kf-bg overflow-hidden position-relative transition-container-width"
>
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100">
    <header class="d-flex w-100 justify-content-between side-header">
      <div class="d-flex flex-grow-1 align-items-center">
        <div class="d-flex align-items-center">
          <button
            *ngIf="showBackButton"
            [attr.data-testcafe]="'sideTransition-button-back'"
            (click)="handleBack()"
            class="mr-2"
            aria-label="Ansicht schließen"
            kfTooltip="Ansicht schließen"
            kfTooltipPosition="before"
            kfTooltipShow="hover"
            mat-icon-button
          >
            <kf-icon name="ic_chevron_links_24"></kf-icon>
          </button>
          <div class="d-flex flex-column">
            <h2
              class="text-truncate mb-0 side-heading"
              [kfTooltip]="transitionData?.name.length > 50 ? transitionData.name : null"
              kfTooltipPosition="below"
              kfTooltipShow="hover"
            >
              {{ transitionData?.name }}
            </h2>
            <ng-container *ngIf="state === transitionState.contact">
              <p class="text-truncate mb-0">Neuer Ansprechpartner des Unternehmens</p>
            </ng-container>
            <ng-container *ngIf="state === transitionState.datepick">
              <p class="text-truncate mb-0">Terminvorschlag</p>
            </ng-container>
          </div>
        </div>
      </div>
    </header>

    <ng-container *ngIf="transitionData; else loading">
      <ng-container *ngIf="!selectionMode; else selectionModeScreen">
        <ng-container *ngIf="state === transitionState.default">
          <ng-template *ngTemplateOutlet="default"></ng-template>
        </ng-container>
        <ng-container *ngIf="state === transitionState.datepick">
          <ng-template *ngTemplateOutlet="datepick"></ng-template>
        </ng-container>
        <ng-container *ngIf="state === transitionState.contact">
          <ng-template *ngTemplateOutlet="contact"></ng-template>
        </ng-container>
        <ng-container *ngIf="state === transitionState.customer">
          <ng-template *ngTemplateOutlet="customer"></ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <button
    [attr.data-testcafe]="'sideTransition-button-close'"
    *ngIf="showCloseButton"
    class="kf-popup-close"
    mat-icon-button
    (click)="handleClose()"
    aria-label="Panel schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>

<!-- TEMPLATES -->

<ng-template #selectionModeScreen>
  <div class="d-flex flex-column side-container">
    <div class="d-flex">
      <div
        class="d-flex flex-column align-items-baseline justify-content-start"
        [class.side-container-left]="transitionData && transitionData.benefits.length > 0"
        [class.w-100]="transitionData && transitionData.benefits.length < 1"
      >
        <div class="mt-4 mr-5 detail-description" [innerHTML]="transitionData.description | safeHtml"></div>

        <ng-container *ngIf="mainData && mainData.id">
          <ng-container *ngTemplateOutlet="badge; context: badgeData"></ng-container>
        </ng-container>

        <ng-container *ngIf="selected">
          <div class="tag-info tag-positive">
            <div class="tag-info-text">Im Lösungskorb</div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="documentCount > 0 || transitionData.benefits.length > 0">
        <div class="d-flex flex-column align-items-baseline justify-content-start side-container-right">
          <ng-container *ngIf="documentCount > 0">
            <div class="mb-5">
              <h3 class="mat-body-1 font-weight-bold mb-1">Dokumente</h3>
              <div class="d-flex w-100">
                <div class="d-flex">
                  <button
                    *ngIf="documentCount > 0"
                    class="button-badge"
                    [attr.data-testcafe]="'sideTransition-button-documents'"
                    [matMenuTriggerFor]="menu"
                    mat-stroked-button
                    color="primary"
                    [matBadge]="documentCount"
                    matBadgeOverlap="true"
                    matBadgePosition="above after"
                    matBadgeColor="primary"
                  >
                    <kf-icon name="ic_datei_24"></kf-icon>
                    <span>Dokumente</span>
                  </button>
                  <mat-menu #menu="matMenu">
                    <ng-container class="d-flex flex-column" *ngFor="let document of documents; let last = last">
                      <button mat-menu-item (click)="showDocument(document, $event)">
                        {{ document.name + ' (' + document.type + ')' }}
                      </button>
                    </ng-container>
                  </mat-menu>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="transitionData.benefits.length > 0">
            <ng-template *ngTemplateOutlet="benefitsList"></ng-template>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="d-flex flex-column mt-4">
      <ng-container *ngIf="selected && transitionData?.appointments?.length > 0">
        <div>
          <h3 class="mat-body-1 font-weight-bold mb-1" [ngPlural]="transitionData?.appointments?.length">
            <ng-template ngPluralCase="=1">Terminvorschlag</ng-template>
            <ng-template ngPluralCase="other">Terminvorschläge</ng-template>
          </h3>
          <ol class="list-unstyled mb-3">
            <li *ngFor="let appointment of appointmentArray">
              <div class="m-auto">
                <div class="tag-info tag-neutral mt-3">
                  <div class="tag-info-text">
                    {{ appointment.appointmentString }}
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>
        <div class="button-row-inline">
          <div class="button-row-content">
            <button
              [attr.data-testcafe]="'sideTransition-button-selectedDate'"
              mat-button
              (click)="toggleSelectionMode()"
            >
              <kf-icon name="ic_edit_24"></kf-icon>
              <span [ngPlural]="transitionData?.appointments?.length">
                <ng-template ngPluralCase="=1">Terminvorschlag</ng-template>
                <ng-template ngPluralCase="other">Terminvorschläge</ng-template>
                ändern
              </span>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <ng-container *ngIf="selected">
          <ng-template *ngTemplateOutlet="isInBasket"></ng-template>
        </ng-container>

        <item-side-datafield-button
          *ngIf="selected && hasDataFieldData"
          (click)="onCustomerDataSelected(true)"
        ></item-side-datafield-button>

        <ng-container *ngIf="!selected">
          <ng-template *ngTemplateOutlet="addToBasket"></ng-template>
        </ng-container>

        <button
          *ngIf="selected && transitionData?.appointments?.length === 0"
          [attr.data-testcafe]="'sideTransition-button-suggestAppointment'"
          mat-flat-button
          color="primary"
          (click)="toggleSelectionMode()"
        >
          <kf-icon name="ic_kalender_termin_24"></kf-icon>
          <span>Termin vorschlagen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #addToBasket>
  <button
    [attr.data-testcafe]="'sideTransition-button-putInCart'"
    mat-flat-button
    color="primary"
    (click)="selectTransition()"
  >
    <span>Hinzufügen</span>
  </button>
</ng-template>

<ng-template #isInBasket>
  <button
    [attr.data-testcafe]="'sideTransition-button-inCart'"
    mat-stroked-button
    color="primary"
    (click)="toggleContextMode()"
  >
    <kf-icon name="ic_loeschen_24"></kf-icon>
    <span>Entfernen</span>
  </button>
</ng-template>
<!-- ---------------------------------------------- -->
<!-- -------------- DEFAULT TEMPLATE -------------- -->
<!-- ---------------------------------------------- -->

<ng-template #default>
  <div class="d-flex flex-column side-container" [formGroup]="transitionForm">
    <div class="d-flex justify-content-between align-items-start" *ngIf="mainData && mainData.id">
      <div class="d-flex flex-column" [class.side-container-left]="mainData && mainData.id">
        <ng-template *ngTemplateOutlet="badge; context: badgeData"></ng-template>
      </div>
    </div>

    <mat-form-field required>
      <mat-label>Experte</mat-label>
      <mat-select
        kfSelectMdc
        (selectionChange)="onExpertSelected($event)"
        [formControl]="transitionFormControls.expertContact"
        placeholder="Experten auswählen"
      >
        <mat-option *ngFor="let option of expertList" [value]="option.value">{{ option.label }}</mat-option>
      </mat-select>
      <mat-error>
        <kf-input-error
          *ngIf="transitionFormControls.expertContact.invalid"
          [message]="getFieldErrorMessage(transitionFormControls.expertContact, 'expertContact')"
        >
        </kf-input-error>
      </mat-error>
    </mat-form-field>

    <!-- ------ CONTACT SELECTION ------ -->
    <div>
      <div class="d-flex align-items-center mb-3">
        <ng-container *ngIf="contactsList.length === 0">
          <button
            [attr.data-testcafe]="'sideTransition-button-consultant'"
            mat-button
            color="primary"
            (click)="state = transitionState.contact"
          >
            <kf-icon name="ic_pluszeichen_24"></kf-icon>
            <span>Ansprechpartner</span>
          </button>
        </ng-container>

        <ng-container *ngIf="contactsList.length > 0">
          <div class="d-flex flex-column w-100">
            <mat-form-field required>
              <mat-label>Ansprechpartner</mat-label>
              <mat-select
                kfSelectMdc
                (selectionChange)="onContactSelected($event)"
                [formControl]="transitionFormControls.customerContact"
                placeholder="Ansprechpartner auswählen"
              >
                <mat-option *ngFor="let option of contactsList" [value]="option.value">{{ option.label }}</mat-option>
              </mat-select>
              <mat-error>
                <kf-input-error
                  *ngIf="transitionFormControls.customerContact.invalid"
                  [message]="getFieldErrorMessage(transitionFormControls.customerContact, 'customerContact')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>

            <div class="d-flex justify-content-end">
              <button
                [attr.data-testcafe]="'sideTransition-button-addConsultant'"
                mat-stroked-button
                color="primary"
                (click)="state = transitionState.contact"
              >
                <kf-icon name="ic_pluszeichen_24"></kf-icon>
                <span>Ansprechpartner</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- ------ COMMENT SELECTION ------ -->
    <mat-form-field class="notranslate" [formGroup]="transitionForm">
      <mat-label>Notiz</mat-label>
      <div class="kf-field-char-counter" aria-hidden="true">{{ transitionComment?.length || 0 }}/4000</div>
      <textarea
        #textArea
        ngDefaultControl
        [(ngModel)]="transitionComment"
        formControlName="individualNote"
        matInput
        maxLength="4000"
        cdkTextareaAutosize
        (change)="onTransitionCommentAdded(textArea.value)"
      ></textarea>
    </mat-form-field>

    <!-- ------ APPOINTMENT SELECTION ------ -->
    <div>
      <div class="d-flex align-items-center">
        <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
        <div class="font-weight-bold">
          {{ appointmentArray.length === 1 ? 'Terminvorschlag' : 'Terminvorschläge' }}
        </div>
      </div>
      <div class="d-flex flex-column mt-2 mb-2">
        <ng-container *ngFor="let appointment of appointmentArray; let i = index; let first = first">
          <div class="w-100 d-flex justify-content-between" [class.mt-3]="!first">
            <div class="m-auto">
              <div class="tag-info tag-neutral">
                <div class="tag-info-text">
                  {{ appointment.appointmentString }}
                </div>
              </div>
            </div>
            <div class="button-row-inline">
              <div class="button-row-content">
                <button
                  [attr.data-testcafe]="'sideTransition-button-closeDate'"
                  (click)="onDatepickDeleted(i)"
                  aria-label="Terminvorschlag löschen"
                  kfTooltip="Terminvorschlag löschen"
                  kfTooltipPosition="after"
                  kfTooltipShow="hover"
                  mat-icon-button
                >
                  <kf-icon name="ic_loeschen_24"></kf-icon>
                </button>
                <button
                  [attr.data-testcafe]="'sideTransition-button-date'"
                  mat-icon-button
                  (click)="onDatepickUpdate(i)"
                  aria-label="Terminvorschlag ändern"
                  kfTooltip="Terminvorschlag ändern"
                  kfTooltipPosition="after"
                  kfTooltipShow="hover"
                >
                  <kf-icon name="ic_edit_24"></kf-icon>
                </button>
              </div>
            </div>
          </div>
          @if (isInvalidDate(appointment)) {
            <mat-error class="mt-2"
              ><kf-input-error message="Termin muss in der Zukunft liegen"></kf-input-error
            ></mat-error>
          }
        </ng-container>
      </div>
      <ng-container>
        <div class="d-flex justify-content-end mb-5">
          <button
            [class.mt-2]="appointmentArray.length > 0"
            [attr.data-testcafe]="'sideTransition-button-date'"
            mat-stroked-button
            color="primary"
            (click)="onDatepickSelected(appointmentArray.length || 0)"
          >
            <kf-icon name="ic_pluszeichen_24"></kf-icon>
            <span>Termin</span>
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- ------ CONFIRMATION SELECTION ------ -->
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="testcafeButtonBack"
          mat-stroked-button
          color="primary"
          (click)="inputValues['useBackToList'] ? handleBack() : handleClose()"
        >
          <span>Abbrechen</span>
        </button>

        <!-- ------ DATAFIELDS SELECTION ------ -->
        <item-side-datafield-button
          *ngIf="hasDataFieldData"
          (click)="onCustomerDataSelected(true)"
        ></item-side-datafield-button>

        <button
          mat-flat-button
          color="primary"
          [disabled]="buttonDisabled"
          [attr.data-testcafe]="testcafeButtonTransfer"
          (click)="onFinalizeTransition()"
        >
          <span>Speichern</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- -------------------------------------------- -->
<!-- -------------- DATE TEMPLATES -------------- -->
<!-- -------------------------------------------- -->
<ng-template #datepick>
  <div class="d-flex flex-column side-container">
    <div class="d-flex flex-column h-100 flex-center">
      <div class="appointment-details border">
        <mat-calendar
          [selected]="tempAppointment?.timeDateString"
          [minDate]="minimumDate"
          [startAt]="tempAppointment?.timeDateString"
          (selectedChange)="onDateSelect($event)"
          color="primary"
        ></mat-calendar>

        <div class="d-flex align-items-center mx-auto pt-3 border-top">
          <div class="d-flex align-items-center mx-3" (click)="openDatepicker(startTime, $event)">
            <mat-form-field class="notranslate">
              <mat-label>Von</mat-label>
              <input
                [attr.data-testcafe]="'datepicker-input-startTime'"
                [format]="24"
                [ngxTimepicker]="startTime"
                [value]="tempAppointment?.timeStartString || ''"
                matInput
                placeholder="SS:MM"
                readonly
                type="text"
              />
            </mat-form-field>

            <ngx-material-timepicker
              #startTime
              [theme]="bg2Theme"
              [minutesGap]="5"
              [format]="24"
              [confirmBtnTmpl]="timeConfirmButton"
              [cancelBtnTmpl]="timeCancelButton"
              (timeSet)="onStartTimeSelect($event)"
            ></ngx-material-timepicker>
          </div>

          <div class="d-flex align-items-center mx-3" (click)="openDatepicker(endTime, $event)">
            <mat-form-field class="notranslate">
              <mat-label>Bis</mat-label>
              <input
                [attr.data-testcafe]="'datepicker-input-endTime'"
                [disabled]="!startTime.time"
                [format]="24"
                [min]="getEndTimeMin(startTime.time)"
                [ngxTimepicker]="endTime"
                [value]="tempAppointment?.timeEndString || ''"
                matInput
                placeholder="SS:MM"
                readonly
                type="text"
              />
            </mat-form-field>

            <ngx-material-timepicker
              #endTime
              [theme]="bg2Theme"
              [minutesGap]="5"
              [format]="24"
              [confirmBtnTmpl]="timeConfirmButton"
              [cancelBtnTmpl]="timeCancelButton"
              (timeSet)="onEndTimeSelect($event)"
            ></ngx-material-timepicker>
          </div>
        </div>

        <ng-template #timeCancelButton>
          <button class="time-button" [attr.data-testcafe]="'sideTransition-button-timeCancel'">Abbrechen</button>
        </ng-template>
        <ng-template #timeConfirmButton>
          <button class="time-button" [attr.data-testcafe]="'sideTransition-button-timeConfirm'">OK</button>
        </ng-template>
      </div>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideTransition-button-cancel'"
          mat-stroked-button
          color="primary"
          (click)="state = transitionState.default"
        >
          <span>Abbrechen</span>
        </button>

        <button
          [attr.data-testcafe]="'sideTransition-button-dateSuggestion'"
          [disabled]="notValidAppointment"
          mat-flat-button
          color="primary"
          (click)="onAppointmentDateConfirmed()"
        >
          <span>Termin vorschlagen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- --------------------------------------------- -->
<!-- ------------- CONTACT TEMPLATES ------------- -->
<!-- --------------------------------------------- -->

<ng-template #contact>
  <div class="d-flex flex-column side-container">
    <div class="d-flex justify-content-between align-items-start">
      <div [formGroup]="contactForm" class="w-100 d-flex flex-column">
        <mat-form-field class="notranslate" class="w-100">
          <mat-label>Anrede</mat-label>
          <mat-select
            #select
            ngDefaultControl
            kfSelectMdc
            placeholder="Anrede auswählen"
            [formControl]="contactFormControls.formOfAddress"
          >
            <mat-option *ngFor="let option of contactsFormOfAddress" [value]="option.value">{{
              option.label
            }}</mat-option>
          </mat-select>
          <mat-error>
            <kf-input-error
              *ngIf="contactFormControls.formOfAddress.invalid"
              [message]="getFieldErrorMessage(contactFormControls.formOfAddress, 'formOfAddress')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>
        <div class="d-flex justify-content-between">
          <div class="w-50 mr-3">
            <mat-form-field class="notranslate">
              <mat-label>Vorname</mat-label>
              <input ngDefaultControl [formControl]="contactFormControls.firstName" matInput type="text" />
              <mat-error>
                <kf-input-error
                  *ngIf="contactFormControls.firstName.invalid"
                  [message]="getFieldErrorMessage(contactFormControls.firstName, 'firstName')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="w-50 ml-3">
            <mat-form-field class="notranslate">
              <mat-label>Nachname</mat-label>
              <input ngDefaultControl [formControl]="contactFormControls.lastName" matInput type="text" />
              <mat-error>
                <kf-input-error
                  *ngIf="contactFormControls.lastName.invalid"
                  [message]="getFieldErrorMessage(contactFormControls.lastName, 'lastName')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <mat-form-field class="notranslate">
            <mat-label>E-Mail-Adresse</mat-label>
            <input
              ngDefaultControl
              [formControl]="contactFormControls.email"
              matInput
              type="email"
              placeholder="Gültige E-Mail-Adresse wie z.B. name.vorname@domain.de"
            />
            <mat-error>
              <kf-input-error
                *ngIf="contactFormControls.email.invalid"
                [message]="getFieldErrorMessage(contactFormControls.email, 'email')"
              >
              </kf-input-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideTransition-button-abort'"
          mat-stroked-button
          color="primary"
          (click)="onContactCancelled()"
        >
          <span>Abbrechen</span>
        </button>

        <button
          [attr.data-testcafe]="'sideTransition-button-addConsultant'"
          mat-flat-button
          color="primary"
          (click)="onContactAdded()"
        >
          <span>Ansprechpartner hinzufügen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- -------------------------------------------- -->
<!-- ------------ CUSTOMER TEMPLATES ------------ -->
<!-- -------------------------------------------- -->

<ng-template #customer>
  <div class="d-flex flex-column side-container">
    <item-datafield-form
      #formItem
      [dataFieldData]="dataFieldData"
      [disableFields]="requestInProgress"
      [dataFieldElementType]="dataFieldElementTypeEnum.transitions"
      (valueChanged)="handleDataFormValueChange($event)"
      [showHeader]="false"
    ></item-datafield-form>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="d-flex h-100 align-items-center button-row">
      <button
        mat-flat-button
        color="primary"
        [attr.data-testcafe]="'sideTransition-button-completeData'"
        (click)="onDataCancelled()"
      >
        <span>Speichern</span>
      </button>
    </div>
  </footer>
</ng-template>

<!-- ------ BADGE TEMPLATE ------ -->
<ng-template #badge let-text="label" let-bgColor="bgColor" let-textColor="textColor" let-type="type" let-id="id">
  <div>
    <button
      class="my-4"
      *ngIf="!!mainData?.id"
      mat-button
      color="primary"
      (click)="onBadgeClicked(type, id, $event)"
      [attr.aria-label]="text === '' ? 'Details anzeigen' : null"
    >
      <span>{{ text }}</span>
      <kf-icon name="ic_weiterleiten_24"></kf-icon>
    </button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <mat-progress-spinner
      [color]="'primary'"
      mode="indeterminate"
      class="m-auto"
      [diameter]="90"
    ></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #benefitsList>
  <div class="d-flex flex-column flex-grow-1 align-items-start justify-content-start flex-scroll">
    <h3 class="mat-body-1 font-weight-bold mb-1">Vorteile</h3>
    <div *ngFor="let benefit of transitionData.benefits">
      <article class="d-flex w-100 align-items-center mt-4">
        <div class="align-self-start">
          <kf-icon class="kf-color-primary" [name]="getBenefitIcon(benefit.type)"></kf-icon>
        </div>
        <div class="ml-3">
          <h4 class="mat-body-2 mb-0 font-weight-bold">{{ benefit.name }}</h4>
          <div [innerHTML]="benefit.description | safeHtml"></div>
        </div>
      </article>
    </div>
  </div>
</ng-template>
