import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { environment } from '@environment/environment';

interface VrImageData {
  url: string;
  altText?: string;
  selected?: boolean;
}

enum QuestionImageBehavior {
  small = 'SMALL',
  large = 'LARGE',
  fixedSmall = 'SMALL_FIXED',
  fixedLarge = 'LARGE_FIXED',
}

interface EnlargeOptions {
  endWidth: string;
  endHeight: string;
}

@Component({
  selector: 'bgzv-image',
  templateUrl: './bgzv-image.component.html',
  styleUrls: ['./bgzv-image.component.scss'],
  animations: [
    trigger('simpleSlideAnimation', [
      transition(':decrement', [
        style({ transform: 'translateX(-12%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':increment', [
        style({ transform: 'translateX(12%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
    ]),

    trigger('simpleFadeAnimation', [
      transition(':leave', [style({ opacity: 0 })]),
      transition(':decrement', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':increment', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
    ]),
  ],
})
export class BgzvImageComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('svgContainer') svgContainer: ElementRef;

  @Input() src: string;
  @Input() alt: string = '';

  @Input() image: VrImageData = null;
  @Input() imageMulti: VrImageData[] = [];
  @Input() behavior: QuestionImageBehavior = QuestionImageBehavior.small;
  @Input() @HostBinding('style.width') width: string;
  @Input() @HostBinding('style.height') height: string;

  @Input() enlargeable: boolean = true;
  @Input() enlargeOptions: EnlargeOptions = { endHeight: '75vh', endWidth: '100%' };

  @Output() currentValueChanged = new EventEmitter<number>();
  @Output() focused = new EventEmitter<any>();

  public logoExpanded = false;
  public slideIndex = 0;
  public animationIndex = 0;

  public imageLoaded = false;
  public imageError = false;

  public enlarged = false;
  private svgStringFromFile = '';

  readonly behaviorEnum = QuestionImageBehavior;
  readonly environment = environment;

  public downloadedImages = [];

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.logoExpanded = this.startLarge;
    if (this.isKfImage) {
      this.imageLoaded = true;
    }

    if (!this.width && !this.src) {
      this.width = '354px';
    } else if (!!this.width) {
      this.width = this.width;
    } else {
      this.width = null;
    }

    this.setInitialIndex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.imageMulti) {
      const arraysEqual = (a1, a2) => a1?.length === a2?.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
      const objectsEqual = (o1, o2) =>
        typeof o1 === 'object' && Object.keys(o1).length > 0
          ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
          : o1 === o2;

      if (!arraysEqual(changes.imageMulti.currentValue, changes.imageMulti.previousValue)) {
        this.setInitialIndex();
      }
    }

    if (changes?.behavior) {
      this.logoExpanded = this.startLarge;
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (!!this.src && !this.isKfImage && this.isSvgImage) {
      const loadSvgAsString = async (url: string) => {
        const response = await fetch(url);

        if (response.ok) {
          const buffer = await response.arrayBuffer();
          this.svgStringFromFile = '';
          new Uint8Array(buffer).forEach(byte => {
            this.svgStringFromFile += String.fromCharCode(byte);
          });
        }
        return '';
      };

      if (!this.src.startsWith('data:image/svg+xml')) {
        await loadSvgAsString(this.src);
        await this.setSvgColorTheme(this.src, this.svgContainer.nativeElement);
      } else {
        await this.setSvgColorTheme(this.src, this.svgContainer.nativeElement);
      }
    }
  }

  public multiImageUrl(slideIndex: number): string {
    if (environment.platform === 'vp' && this.imageMulti[slideIndex].url.includes('bgzv-service-nginx')) {
      const host = `${window.location.protocol}//${window.location.host}`;
      return `${host}/bws/serviceproxy${this.imageMulti[slideIndex].url}`;
    }
    return this.imageMulti[slideIndex].url;
  }

  public changeActiveIndex(increment): void {
    if (increment) {
      this.slideIndex = (this.slideIndex + 1) % this.imageMulti.length;
      this.animationIndex++;
    } else {
      this.slideIndex = (this.slideIndex + this.imageMulti.length - 1) % this.imageMulti.length;
      this.animationIndex--;
    }

    this.currentValueChanged.emit(this.slideIndex);
  }

  public onResizeClick(ev: Event): void {
    this.logoExpanded = this.resizable ? !this.logoExpanded : this.logoExpanded;
  }

  public onImageLoad(ev): void {
    this.imageLoaded = true;
  }

  public onImageError(ev): void {
    this.imageError = true;
  }

  public handleKeyup(event: KeyboardEvent, ref: any): void {
    if (event.key === 'Tab') {
      if (ref === document.activeElement || ref?.contains(document.activeElement)) {
        this.focused.emit({ ref: ref });
        ref.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }

  public setSvgColorTheme(base64SvgString: string, element: HTMLDivElement): any {
    if (element.children.length > 0) {
      return;
    }

    let svgString = '';
    if (base64SvgString.startsWith('data:image/svg+xml')) {
      svgString = atob(base64SvgString.substring(26));
    } else {
      svgString = this.svgStringFromFile;
    }

    if (!svgString) {
      return;
    }

    const wrapper = document.createElement('div');
    wrapper.innerHTML = svgString;
    const newSvg = <HTMLElement>wrapper.children[0];
    newSvg.setAttribute('width', '100%');
    newSvg.setAttribute('height', '100%');
    const svgPaths = newSvg.getElementsByTagName('path');
    const svgGroups = newSvg.getElementsByTagName('g');

    Array.from(svgPaths).forEach(path => {
      this.replaceSvgColors(path, 'fill');
      this.replaceSvgColors(path, 'stroke');
    });

    Array.from(svgGroups).forEach(group => {
      this.replaceSvgColors(group, 'fill');
      this.replaceSvgColors(group, 'stroke');
    });

    if (element.children.length === 0) {
      this.renderer.appendChild(element, newSvg);
    }

    return '';
  }

  private setInitialIndex(): void {
    if (this.imageMulti.length > 0) {
      this.slideIndex = Math.max(
        this.imageMulti.findIndex(x => x.selected === true),
        0
      );
    }
  }

  private replaceSvgColors(svgElement: SVGPathElement | SVGElement, attribute: string) {
    if (svgElement.attributes.getNamedItem(attribute)) {
      const currentColor = svgElement.attributes.getNamedItem(attribute).value;
      svgElement.attributes.getNamedItem(attribute).value = this.replaceColorWithVars(currentColor);
    }
  }

  private replaceColorWithVars(color: string): string {
    const replacement = {
      '--color-illustration-ultra-primary': ['#0066B3', '#000000', '#000', 'black'],
      '--color-illustration-ultra-accent': ['#FF6200'],
      '--color-illustration-ultra-primary-variant': ['#002D67'],
      '--color-illustration-ultra-primary-1': ['#327DC8'],
      '--color-illustration-ultra-primary-2': ['#649BD7'],
      '--color-illustration-ultra-primary-3': ['#96BEE6'],
      '--color-illustration-ultra-primary-4': ['#E1EBF5'],
      '--color-illustration-ultra-primary-5': ['#FFFFFF', '#FFF', 'white'],
      '--color-illustration-ultra-skin-light': ['#FFCDC3'],
      '--color-illustration-ultra-skin-medium': ['#F0AFA5'],
      '--color-illustration-ultra-skin-dark': ['#906A5D'],
      '--color-illustration-ultra-contours': ['#002D66', '#002D67'], // color in 8840 profile #002D67
    };

    for (const r in replacement) {
      if (replacement[r].some(e => e.toLowerCase().search(color.toLowerCase()) !== -1)) {
        return `var(${r})`;
      }
    }

    return color;
  }

  get singleImageUrl(): string {
    return this.image.url;
  }

  get isSvgImage(): boolean {
    const defaultSvg =
      this.src?.endsWith('.svg') ||
      this.image?.url?.endsWith('.svg') ||
      this.imageMulti[this.slideIndex]?.url?.endsWith('.svg');
    const base64Svg =
      this.src?.startsWith('data:image/svg+xml') ||
      this.image?.url?.startsWith('data:image/svg+xml') ||
      this.imageMulti[this.slideIndex]?.url?.startsWith('data:image/svg+xml');

    return defaultSvg || base64Svg;
  }

  get isKfImage(): boolean {
    // we can't search for 'assets' as this will be replaced after building on Atruvia
    const searchTerm = 'sets/illus';
    if (!!this.src) {
      return this.src.includes(searchTerm);
    } else if (!!this.image) {
      return this.image?.url.includes(searchTerm) || false;
    } else if (this.imageMulti.length > 0) {
      return this.imageMulti[this.slideIndex]?.url.includes(searchTerm) || false;
    }
    return false;
  }

  get kfImageUrl(): string {
    if (environment.platform === 'vp') {
      // the prefix is only used for local development
      const path = 'https://r8840-e42-e.t1.web.fiducia.de/services_cloud/portal/kf-theme/assets?f=';
      const prefix = environment.isLocal ? path : '';
      if (!!this.src) {
        return `${prefix}${this.src}`;
      } else if (!!this.image) {
        return `${prefix}${this.image.url}`;
      } else if (this.imageMulti.length > 0) {
        return `${prefix}${this.imageMulti[this.slideIndex].url}`;
      }
    }
    return '';
  }

  get resizable(): boolean {
    return this.behavior === this.behaviorEnum.small || this.behavior === this.behaviorEnum.large;
  }

  get startLarge(): boolean {
    return this.behavior === this.behaviorEnum.large || this.behavior === this.behaviorEnum.fixedLarge;
  }
}
