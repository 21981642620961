<div class="d-flex flex-column h-100 w-100 kf-bg">
  <marginals-header class="w-100"></marginals-header>
  @if (!checkoutFinished) {
    <div class="d-flex flex-column flex-grow-1 flex-scroll">
      <header class="w-100 checkout-main-margin mt-4 checkout-header">
        <kf-headline
          [resetTopSpacing]="true"
          headline="Gewählte Lösungen"
          [showBackButton]="false"
          class="contents-forced-top-alignment"
        >
          <button
            [attr.data-testcafe]="'screenCheckout-button-sideNotes'"
            [disabled]="isLoading"
            (click)="onOpenNotes()"
            mat-stroked-button
            color="primary"
          >
            <kf-icon name="ic_herunterladen_24"></kf-icon>
            <span>Notizen & Dokumente</span>
          </button>
          <div [class.has-error]="hasError('general')">
            <ng-template
              *ngTemplateOutlet="dataFieldButton; context: { item: generalGroup, cId: -1, type: 'general' }"
            ></ng-template>
          </div>
        </kf-headline>
      </header>
      @if (hasCompositions || hasContentElements) {
        <div class="checkout-main-grid mb-4">
          @for (composition of checkoutCompositions; track composition.id) {
            @if (composition.media && composition.media.url) {
              <bgzv-image class="composition-image" [alt]="'illustration'" [src]="composition.media.url"></bgzv-image>
            } @else {
              <bgzv-image
                class="composition-image"
                [alt]="'illustration'"
                [src]="assetPath + '/images/illu_zahnrad_duotone.svg'"
                aria-hidden="true"
              ></bgzv-image>
            }
            <div>
              <h2 class="mb-3">{{ composition.name }}</h2>
              <ng-template
                *ngTemplateOutlet="product; context: { data: composition.products, cId: composition.id }"
              ></ng-template>
              <ng-template
                *ngTemplateOutlet="task; context: { data: composition.tasks, cId: composition.id }"
              ></ng-template>
              <ng-template
                *ngTemplateOutlet="transition; context: { data: composition.transitions, cId: composition.id }"
              ></ng-template>
            </div>
          }
          @if (hasContentElements) {
            <img width="80" height="66" alt="" src="{{ assetPath }}/images/icon-task.svg" />
            <div>
              <h2 class="mb-3">Themen</h2>
              <ng-template *ngTemplateOutlet="contentElement; context: { data: contentElements }"></ng-template>
            </div>
          }
        </div>
      } @else {
        <div class="no-composition">
          <div class="d-flex flex-column align-items-center">
            <img alt="" class="empty-recommendation-icon" src="{{ assetPath }}/images/empty-recommendation-icon.svg" />
            <p class="mt-3 empty-message">
              Es wurden keine Produkte, Maßnahmen, Expertengespräche oder Themenempfehlungen gewählt.
            </p>
          </div>
        </div>
      }
    </div>
    @if (hasCompositions || hasContentElements) {
      <section class="checkout-main-grid py-3 kf-bg-grey-200" [class.side-footer-margin]="useMargin">
        <div></div>
        <div>
          <ng-template *ngTemplateOutlet="totals; context: { data: checkoutTotals }"></ng-template>
        </div>
      </section>
    }
  }
</div>

<ng-template #product let-data="data" let-cId="cId">
  @if (data.length > 0) {
    <div class="mat-small kf-bg-grey-100 font-weight-bold py-1 checkout-production-grid">
      <span class="checkout-place-start">Produkt</span>
      <span class="checkout-place-start">Abrechnung</span>
      <span class="checkout-place-end">Einzelpreis</span>
      <span class="checkout-place-end">Menge</span>
      <span class="checkout-place-end">Nettopreis</span>
      <span class="checkout-place-end">Bruttopreis</span>
      <span class="checkout-place-center"></span>
    </div>
    @for (item of data; track item.id; let i = $index, last = $last) {
      <div
        id="checkout_{{ item.id }}"
        class="checkout-production-grid py-2"
        [class.last]="last"
        [class.has-error]="hasError(item.id)"
      >
        <div class="checkout-place-start">
          <h4 class="mat-body-2 font-weight-bold item-name text-break mb-0">{{ item.name }}</h4>
        </div>
        <div class="checkout-place-start">
          @if (item.fixCost) {
            <div>{{ item.fixCost.paymentInterval | payInterval }}</div>
          }
          @if (item.runningCost) {
            <div>{{ item.runningCost.paymentInterval | payInterval }}</div>
          }
        </div>
        <div class="checkout-place-end">
          @if (item.fixCost) {
            <div markNegativePrice>{{ formatPrice(item.fixCost.netPrice) }}</div>
          }
          @if (item.runningCost) {
            <div markNegativePrice>{{ formatPrice(item.runningCost.netPrice) }}</div>
          }
        </div>
        <div class="checkout-place-end">
          @if (item.fixCost || (!item.fixCost && !item.runningCost)) {
            <div>{{ item.selectedQuantity }}</div>
          }
          @if (item.runningCost) {
            <div>{{ item.selectedQuantity }}</div>
          }
        </div>
        <div class="checkout-place-end">
          @if (item.fixCost) {
            <div markNegativePrice>{{ formatPrice(item.fixCost.netPrice, item.selectedQuantity) }}</div>
          }
          @if (item.runningCost) {
            <div markNegativePrice>{{ formatPrice(item.runningCost.netPrice, item.selectedQuantity) }}</div>
          }
        </div>
        <div class="checkout-place-end">
          @if (item.fixCost) {
            <div markNegativePrice>{{ formatPrice(item.fixCost.grossPrice, item.selectedQuantity) }}</div>
          }
          @if (item.runningCost) {
            <div markNegativePrice>{{ formatPrice(item.runningCost.grossPrice, item.selectedQuantity) }}</div>
          }
        </div>
        <div class="checkout-place-center">
          <ng-template
            *ngTemplateOutlet="dataFieldButton; context: { item: item, cId: cId, type: 'products' }"
          ></ng-template>
        </div>
        <div class="checkout-place-center">
          <ng-template
            *ngTemplateOutlet="contractStatusDisplay; context: { item: item, cId: cId, type: 'products' }"
          ></ng-template>
        </div>
      </div>
    }
  }
</ng-template>

<ng-template #task let-data="data" let-cId="cId">
  @if (data.length > 0) {
    <div class="mat-small checkout-transition-grid kf-bg-grey-100 font-weight-bold py-1">
      <span class="checkout-place-start">Maßnahme</span>
      <span class="checkout-place-start">Fälligkeitsdatum</span>
      <span class="checkout-place-start column-span-4">Verantwortlich</span>
      <span class="checkout-place-center"></span>
    </div>
    @for (item of data; track item.id; let last = $last) {
      <div
        id="checkout_{{ item.id }}"
        class="checkout-transition-grid py-2"
        [class.last]="last"
        [class.has-error]="hasError(item.id)"
      >
        <div class="checkout-place-start">
          <h4 class="mat-body-2 font-weight-bold item-name text-break mb-0">{{ item.name }}</h4>
        </div>
        <div class="checkout-place-start">{{ formatTaskDate(item.appointmentDate) }}</div>
        <div class="checkout-place-start column-span-4">{{ formatContact(item.customerContact) }}</div>
        <div class="checkout-place-center">
          <button
            mat-stroked-button
            color="primary"
            [disabled]="isLoading"
            [attr.data-testcafe]="'checkoutScreen-button-assign-' + getTestcafeId(item.name)"
            (click)="openTaskAssignment(cId, item.name, item)"
          >
            <span>{{ item.customerContact ? 'Zuweisung ändern' : 'Zuweisen' }}</span>
          </button>
          <ng-template *ngTemplateOutlet="errorHint"></ng-template>
        </div>
      </div>
    }
  }
</ng-template>

<ng-template #transition let-data="data" let-cId="cId">
  @if (data.length > 0) {
    <div class="mat-small checkout-transition-grid kf-bg-grey-100 font-weight-bold py-1">
      <span class="checkout-place-start">Expertengespräche</span>
      <span class="checkout-place-start">Terminbeginn</span>
      <span class="checkout-place-start column-span-2">Verantwortlich</span>
      <span class="checkout-place-start column-span-2">Ansprechpartner des Unternehmens</span>
      <span class="checkout-place-center"></span>
    </div>
    @for (item of data; track item.id; let last = $last) {
      <div
        id="checkout_{{ item.id }}"
        class="checkout-transition-grid py-2"
        [class.last]="last"
        [class.has-error]="hasError(item.id)"
      >
        <div class="checkout-place-start">
          <h4 class="mat-body-2 font-weight-bold item-name text-break mb-0">{{ item.name }}</h4>
        </div>
        <div class="checkout-place-start">
          @for (date of item.appointments; track date.appointmentStart) {
            <div>{{ formatTransitionDate(date.appointmentStart) }}</div>
          }
        </div>
        <div class="checkout-place-start column-span-2">{{ formatContact(item.expertContact) }}</div>
        <div class="checkout-place-start column-span-2">{{ formatContact(item.customerContact) }}</div>
        <div class="checkout-place-center">
          @if (item.expertContact) {
            <ng-template *ngTemplateOutlet="dataFieldButton; context: { item, cId, type: 'transitions' }">
            </ng-template>
          } @else {
            <button
              [disabled]="isLoading"
              [attr.data-testcafe]="'checkoutScreen-button-dateSuggestion'"
              (click)="openTransitionAssignment(cId, item.name, item)"
              mat-stroked-button
              color="primary"
            >
              <span>Termin vorschlagen</span>
            </button>
            <ng-template *ngTemplateOutlet="errorHint"></ng-template>
          }
        </div>
      </div>
    }
  }
</ng-template>

<ng-template #contentElement let-data="data">
  @if (data.length > 0) {
    <div class="mat-small checkout-transition-grid kf-bg-grey-100 font-weight-bold py-1">
      <span class="checkout-place-start">Themenempfehlung</span>
      <span class="checkout-place-start">Fälligkeitsdatum</span>
      <span class="checkout-place-start column-span-4">Verantwortlich</span>
      <span class="checkout-place-center"></span>
    </div>
    @for (item of data; track item.id; let last = $last) {
      <div
        id="checkout_{{ item.id }}"
        class="checkout-transition-grid py-2"
        [class.last]="last"
        [class.has-error]="hasError(item.id)"
      >
        <div class="checkout-place-start">
          <h4 class="mat-body-2 font-weight-bold item-name text-break mb-0">{{ item.name }}</h4>
        </div>
        <div class="checkout-place-start">{{ formatTaskDate(item.appointmentDate) }}</div>
        <div class="checkout-place-start column-span-4">{{ formatContact(item.customerContact) }}</div>
        <div class="checkout-place-center">
          <button
            mat-stroked-button
            color="primary"
            [disabled]="isLoading"
            [attr.data-testcafe]="'checkoutScreen-button-assign-' + getTestcafeId(item.name)"
            (click)="openTopicAssignment(item)"
          >
            <span>{{ item.customerContact ? 'Zuweisung ändern' : 'Zuweisen' }}</span>
          </button>
          <ng-template *ngTemplateOutlet="errorHint"></ng-template>
        </div>
      </div>
    }
  }
</ng-template>

<ng-template #dataFieldButton let-item="item" let-cId="cId" let-type="type">
  @if (item) {
    @if (type === 'general') {
      <button
        mat-stroked-button
        id="checkout_general"
        color="primary"
        [disabled]="isLoading"
        [attr.data-testcafe]="getTestCafeData(item.dataFieldStatus)"
        (click)="onOpenDataFields(item, cId, type)"
      >
        <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
        <span>Allgemeine Kundendaten</span>
      </button>
    } @else if (item.dataFieldStatus !== dataFieldStatus.none) {
      <button
        mat-stroked-button
        color="primary"
        [disabled]="isLoading"
        [attr.data-testcafe]="getTestCafeData(item.dataFieldStatus)"
        (click)="onOpenDataFields(item, cId, type)"
      >
        @switch (item.dataFieldStatus) {
          @case (dataFieldStatus.notComplete) {
            <span>Kundendaten</span>
          }
          @case (dataFieldStatus.mandatoryDone) {
            <span>Vervollständigen</span>
          }
          @case (dataFieldStatus.complete) {
            <kf-icon name="ic_haken_24"></kf-icon>
            <span>Fertig</span>
          }
        }
      </button>
    }
    <ng-template *ngTemplateOutlet="errorHint"></ng-template>
  }
</ng-template>

<ng-template #errorHint>
  <div class="error-hint mt-1">
    <div class="d-flex">
      <kf-icon class="mr-2" name="ic_warnschild_24"></kf-icon>
      <span class="text-nowrap">Angaben erforderlich</span>
    </div>
  </div>
</ng-template>

<ng-template #totals let-data="data">
  <section class="checkout-totals-grid">
    <div class="grid-span-8">
      <h3 class="mat-body-1 font-weight-bold mb-0">Gesamtsumme</h3>
      <p class="mat-small">
        Entspricht der Bruttopreis dem Nettopreis, so handelt es <br />
        sich um umsatzsteuerbefreite Bankdienstleistungen.
      </p>
    </div>
    @for (item of data; track item.id) {
      <p class="checkout-place-end">{{ item.type | payInterval }}</p>
      <p class="checkout-place-end font-weight-bold" markNegativePrice>{{ formatPrice(item.netPrice) }}</p>
      <p class="checkout-place-end font-weight-bold" markNegativePrice>{{ formatPrice(item.grossPrice) }}</p>
    }
  </section>
</ng-template>

<ng-template #contractStatusDisplay let-product="item">
  @if (getHasContractForm(product.id)) {
    @if (getContractPDFViewable(product)) {
      <!-- state: pdf can be viewed -->
      <a
        class="d-flex align-items-center kf-color-primary"
        tabindex="0"
        aria-label="Vertragsdokument kann angezeigt werden"
        kfTooltip="Vertragsdokument kann angezeigt werden"
        kfTooltipShow="hover"
        kfTooltipPosition="before"
      >
        <kf-icon name="ic_pdf_24"></kf-icon>
      </a>
    } @else if (!getContractPending(product) && !getContractError(product)) {
      <!-- state: no errors, not pending: pdf can be created -->
      <a
        tabindex="0"
        class="d-flex align-items-center kf-color-grey-400"
        kfTooltip="Vertragsvorschau kann generiert werden"
        kfTooltipShow="hover"
        kfTooltipPosition="before"
      >
        <kf-icon name="ic_pdf_24"></kf-icon>
      </a>
    }
  }

  @if (getContractPending(product)) {
    <!-- state: pending -->
    <a
      class="d-flex align-items-center"
      tabindex="0"
      aria-label="Vertragsvorschau wird generiert"
      kfTooltip="Vertragsvorschau wird generiert"
      kfTooltipPosition="before"
      kfTooltipShow="hover"
    >
      <mat-spinner [diameter]="24" [color]="'primary'"></mat-spinner>
    </a>
  }

  @if (getContractError(product)) {
    <!-- state: error -->
    <a
      class="d-flex align-items-center kf-color-negative"
      [attr.aria-label]="getTooltip(product)"
      [kfTooltip]="getTooltip(product)"
      kfTooltipPosition="before"
      kfTooltipShow="hover"
      tabindex="0"
    >
      <kf-icon class="mr-2" name="ic_warnschild_24"></kf-icon>
    </a>
  }
</ng-template>
